import React from "react";
import { useContactModal } from "../Modal/contactModalContext";
import RevealAnimation from "../RevealAnimation";
import Button from "../Button";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import { navigate } from "gatsby";

function CtaCardSection({
  className = "",
  titleClassName = "",
  title,
  imageUrl,
  imgClassName = "",
  showBtn = false,
  showIcon = true,
  route = "",
  label = "Contact us",
}) {
  const { setOpenModal } = useContactModal();
  return (
    <div className={`ctaCard ${className}`}>
      <div className="cta-card-container body-container">
        <div className="ctaLeft cta-left-2">
          <RevealAnimation
            component={"h3"}
            className={`title-header ctsTitleAnimation ${titleClassName}`}
            onClick={() => {
              if (route) {
                navigate(route);
              } else {
                setOpenModal(true);
              }
            }}
          >
            {title}
          </RevealAnimation>
          {showBtn && (
            <RevealAnimation className={`cta-btn-wrap`}>
              <Button
                btnWrapClassName=""
                icon={
                  showIcon ? (
                    <img
                      className="effect-btn-svgicon"
                      src={RightArrowWhite}
                      alt={label}
                    />
                  ) : (
                    <></>
                  )
                }
                customClassName="info-view-resize-btn"
                color="primary"
                variant="contained"
                label={label}
                onClick={() => {
                  if (route) {
                    navigate(route);
                  } else {
                    setOpenModal(true);
                  }
                }}
                tooltip="no"
              />
            </RevealAnimation>
          )}
        </div>
        <RevealAnimation
          animation="zoom-in"
          className={`ctaCardImage ${imgClassName}`}
        >
          <img src={imageUrl} alt="" className="ctaCard2" />
        </RevealAnimation>
      </div>
    </div>
  );
}

export default CtaCardSection;
